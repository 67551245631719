

@media (max-width: 1200px) {
    .col3{
        display: none;
    }
      
  }

@media (max-width: 768px) {
    .col3{
       display: none;
    }
    
}

@media (max-width: 576px) {
    .col3{
        display: none;
    }
    
}


  