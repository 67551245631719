.element2 {
    border: 1px solid rgba(179, 179, 179, 1);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    margin-top: 20px;
  }
  .element3 {
    border: 1px solid rgba(179, 179, 179, 1);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 100px;
  }

  .element4 {
    border: 1px solid rgba(179, 179, 179, 1);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    margin-top: 20px;
  }

  .img-logo{
    max-width: 150px;
    max-height: 150px;
  }

  .col-c1{
    justify-content: start;
    display: flex;
    align-items: center;
   
  }

  .col-c2{
    justify-content: end;
    display: flex;
    align-items: center;
  }


  .stack-1{
    margin-bottom: 10px;
  }
  .stack-2{
    margin-bottom: 15px;
  }
  
  .row-r1{
    padding: 40px;
   
    
  }

  .p-date-date{
    margin-left: 5px;
    margin-top: 9px
    
  } 

  .p-date-name{

    margin-top: 0
    
  } 

  .p-invoice-number
  {
    font-size: 0.9rem;
    border-radius: 4px;
    line-height: 1.5;
    padding: 5px 10px;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    border: 2px solid #ebedf0;
    color: rgb(14, 14, 16);
    background: #f5f7f9;
    display: block;
    width: 100%;
 
  }

  .p-invoice-number:hover {
    border-color: #ccc;
}

.input-date{
  font-size: 0.9rem;
    border-radius: 4px;
    line-height: 1.5;
    padding: 5px 10px;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    border: 2px solid #ebedf0;
    color: rgb(14, 14, 16);
    background: #f5f7f9;
    display: block;
    width: 100%;
    
}
.input-date:hover {
  border-color: #e0e0e0;
}

.input-date.custom-border{
  border:2px solid #e4461b;
}

.font-h6{
  font-size: 1rem;
}




.input-due-date{
  font-size: 0.9rem;
    border-radius: 4px;
    line-height: 1.5;
    padding: 5px 10px;
    transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
    border: 2px solid #ebedf0;
    color: rgb(14, 14, 16);
    background: #f5f7f9;
    display: block;
    width: 100%;
    
}
.input-due-date:hover {
  border-color: #e0e0e0;
}

.input-due-date.custom-border{
  border:2px solid #e4461b;
}


.divider {
  height: 1px;
  background-color: #ddd; /* Light gray color for the divider */
  margin: 1.5rem 0;
  margin-left: 20px;
  margin-right: 20px;
  /* Custom margin for spacing */
}
.divider-2{
  height: 1px;
  background-color: #ddd; /* Light gray color for the divider */
  margin: 1.5rem 0;
}

.input-template
{
  
  font-size: 14px;
  border-radius: 6px;
  line-height: 1.5;
  padding: 5px 10px;
  transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
  border: 2px solid #f0edeb;
  color: rgb(14, 14, 16);
  background: #f5f7f9;
  display: block;
  height: 36px;
  width: 100%;
  margin-top: 10px;
  
}

.input-template:hover {
  border-color: #e0e0e0;
}

.input-template.custom-border{
  border:2px solid #e4461b;
}



.billing-row{
  padding: 40px;
}


.line-div {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px
  
}

.row-r2
{

}
.row-r3
{
  padding-left: 20px;
  padding-right: 20px;
}
.row-r4
{
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}


.h6-table{
  margin-left: 0px  ;
}

.remove-button{
  
  
  display: inline-block;
  outline: 0;
  text-align: center;
  cursor: pointer;
  height: 34px;
  padding: 0 13px;
  color: #fff;
  vertical-align: top;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all .3s ease;
  background: #cc4d29;
  border-color: #cc4d29;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 11px;
 
  width: 100%;
  margin-top: 5px ;
  

}
.remove-button:hover{
  background: #e4461b;
      border-color: #e4461b;
}

.add-button{
  
  display: inline-block;
  outline: 0;
  text-align: center;
  cursor: pointer;
  height: 34px;
  padding: 0 13px;
  color: #fff;
  vertical-align: top;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all .3s ease;
  background: #0d6efd;
  border-color: #0d6efd;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 11px;
  margin-left: 20px;

  

}
.add-button:hover {
  background: #0b5ed7;
  border-color: #0b5ed7;
}

.col-subtotal-h2{
  display: flex;
  justify-content: start;
}
.col-subtotal-val-p{
  display: flex;
  justify-content: end;
}
.stack-notes{
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;
}

.col-download-btn{
  display: flex;
  justify-content: end  ;
}



.col-signature-image{
  display: flex;
  justify-content: end;
}

.image-wrapper {
  width: 180px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

}

.image-wrapper2 {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

}


.logo-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  cursor: pointer;
}

.col-bank-details{
  margin: 20px;
}

.div-bank-info{
margin-top: 20px;
max-width: 50%;
}

.form-check{
  font-size: 1rem;
}

.img-buy-me{
  width:200px;
}

.download-btn{
  
  
  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 40px;
  font-size: 16px;
  border-radius: .5rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  width:200px;
 

  
  
} 
.download-btn:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}




 /* Media queries to adjust base font size */
 @media (max-width: 1200px) {
  
}







@media (max-width: 992px) {
  .p-date-date{
    margin-top: 5px;
  }

  .div-bank-info{
    max-width: 70%;
    }

    
}

.col-gst-select{
  padding: 20px;
}







@media (max-width: 768px) {
  .p-date-date{
    margin-top:6px;
  }

  .row-r1{
    padding: 8px;

  
  }
  .col-c2{
    align-items:normal;
    justify-content: start;
  }

  .div-bank-info{
    max-width: 80%;
    }

    .h6-modified{
      font-size: 1.25rem; /* Adjust to match h5 font size for mobile */
      /* Add any additional styling needed for h6 on mobile */
    }


    .div-billfrom{
      margin-top: 40px;
    }
   
    .form-check{
      font-size: 1rem;
    }
    .img-buy-me{
      width:100%;
    }

    .download-btn{
      width:100%;
    }
}







@media (max-width: 576px) {

  .font-h6{
    font-size: 0.75rem;
  }

  .form-check{
    font-size: 1rem;
  }
  
  
  .p-date-date{
    margin-top: 5px;
  }

  .row-r1{
    padding: 15px;

  }

  .col-c2{
    align-items:normal;
    justify-content: start;
  }

  .input-field
  {
    font-size: 0.7rem
  }

  .div-bank-info{
    max-width: 100%;
    }

    .h6-modified{
      font-size: 1rem; /* Adjust to match h5 font size for mobile */
      /* Add any additional styling needed for h6 on mobile */
    }

    .col-billfrom{
      margin-top: 20px;
    }
   
    .img-buy-me{
      width:100%;
    }
  
    .download-btn{
      width:100%;
    }
}



