.footer-main{
    background-color: white;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
    border-top: 2px solid #b3b3b3;
}

.p-footer-copywrite{
    text-align: center;
    margin-top: 30px;
    color: rgb(75, 75, 75);
}

.link-main-footer{
    text-decoration: none;
    color: inherit;
    padding: 6px;
   
}
