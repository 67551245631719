.top-bar-element{
        border: 1px solid rgba(179, 179, 179, 1);
        border-radius: 8px;
        background-color: rgb(255, 255, 255);
        padding: 10px;
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
}

.nav-footer{
margin-top: 10px;
}

.top-bar-heading{
   margin-left: 5%;
   margin-right: 5%;
   margin-top: 30px;
   margin-bottom: 20px;
    color: rgb(32, 32, 32);
    text-align: center;

}

.top-bar-para{
    text-align: center;
    margin-left: 15%;
    margin-right: 15%;
 
}

.stack-buttons{
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
    margin-bottom: 30px;
}
.link{
    display: flex;
    width: 100%;

}

.button-quotation{
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 20px;
    width: 100%;
}

@media (max-width: 992px) {
    .top-bar-heading{
        margin-left: 20px;
        margin-right: 20px;
    }

    .top-bar-para{
        
        margin-left: 20px;
        margin-right: 20px;
     
    }
      
  }
  

  
@media (max-width: 768px) {
   
    .top-bar-heading{
        margin-left: 20x;
        margin-right: 20px;
    }

    .top-bar-para{
       
        margin-left: 20px;
        margin-right: 20px;
     
    }
}

@media (max-width: 576px) {
    .top-bar-heading{
        margin-left: 20px;
        margin-right: 20px;
    }

    .top-bar-para{
        
        margin-left: 20px;
        margin-right: 20px;
     
    }
}


