.side-bar-element-back {
    border: 1px solid rgba(179, 179, 179, 1);
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    margin-top: 20px;
    height: 50%;
    width: 100%;
 
  }

  .reset-btn{
    display: inline-block;
  outline: 0;
  text-align: center;
  cursor: pointer;
  height: 34px;
  padding: 0 13px;
  color: #fff;
  vertical-align: top;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all .3s ease;
  background: #cc4d29;
  border-color: #cc4d29;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 11px;
  width: 100%;
   
  }

  .reset-btn:hover{
    background: #e4461b;
    border-color: #e4461b;
  }

