.link-main{
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none;
    color: inherit;
    font-size: 1rem;
    padding: 20px;


}


.link-main:hover{
    color: grey;
}

.img-navbar{
    margin-right: 7px;
    margin-left: 6px;
    
}

