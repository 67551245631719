.app-ad-div{
width: 50%;
justify-content: center;
}

.app-ad-div-parent{
    width: 100%;
    justify-content: center;
    display: flex;
    background-color: white;
    z-index: 1000;
}


@media (max-width: 992px) {
    .app-ad-div{
        width: 90%;
        justify-content: center;
        }
  }
  
  
  @media (max-width: 768px) {
    .app-ad-div{
        width: 80%;
        justify-content: center;
        }
  }
  
  @media (max-width: 576px) {
  
    .app-ad-div{
        width: 100%;
        justify-content: center;
        }
  }
  
  
  
  